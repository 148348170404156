@import (reference) "~antd/dist/antd.less";

.logo {
  text-align: center;
  padding-bottom: 30px;
}

.logo-img {
  padding: 15px 0px;
}

.menu-logo-btn {
  background: none;
  all: unset;
}

.header {
  background: @white;
}

// Old Header Search Styling
// .header > *:focus, .header:focus, .header > *:hover, .header:hover, .header > :active, .header:active {
//   outline: 0;
//   border: transparent;
//   box-shadow: none;
// }

// .header-search {
//   width: 100%;
//   height: 100%;
//   padding: 0px;
//   float: left;
//   border: 0px;
// }

// .header-search input[type='text'] {
//   font-size: @font-size-base + 4;
// }

// .header-search-logo {
//   font-size: @font-size-base + 4;
//   padding: 0px 10px 0px 0px;
// }

/* SIDER */

// Sider Spacing
.sider-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.user-menu {
  padding-bottom: 60px;
}

.user-text {
  color: @white;
}

.user-avatar {
  background: orange;
  vertical-align: -0.65em;
  margin-right: 10px;
}

// Sider Transition Modifications
.ant-menu-item .ant-avatar + span,
.ant-menu-submenu-title .ant-avatar + span {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item .ant-avatar,
.ant-menu-submenu-title .ant-avatar {
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    margin-left 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-avatar + span,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-avatar
  + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-avatar,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-avatar {
  margin-left: -8px;
  transition: margin-left 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* LAYOUT */
.site-layout-content-wrapper {
  padding: 24px;
}

@font-size-base: 16px;@font-size-sm: 14px;@dropdown-line-height: 24px;@modal-header-title-line-height: 24px;@font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;