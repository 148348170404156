@import "../../index";

.reset-background {
  background-color: #dbf1ff;
}

.form-layout {
  background: @white;
  box-shadow: 0 10px 16px 0;
  padding: 30px 5px;
}

#login-form-forgot {
  float: right;
  border-radius: 25px !important;
  padding-right: 0px;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.form-button {
  width: 75%;
  border-radius: 25px !important;
}

.disclaimer {
  font-size: 8px;
}

@media (min-width: 576px) {
  .form-layout {
    padding: 30px 5px;
  }
  .data-input {
    padding-right: 50px;
  }
}

@media (min-width: 768px) {
  .form-layout {
    padding: 30px 15px;
  }
  .data-input {
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .form-layout {
    padding: 30px 20px;
  }
  .data-input {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .form-layout {
    padding: 30px 30px;
  }
  .data-input {
    padding-right: 45px;
  }
}

@font-size-base: 16px;@font-size-sm: 14px;@dropdown-line-height: 24px;@modal-header-title-line-height: 24px;@font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;