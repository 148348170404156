@import (reference) "~antd/dist/antd.less";

/* HEADER */

.patient-header-page-header {
  padding-top: 0;
}

.patient-header-breadcrumb {
  padding-left: 24px; // to match page header
}

.patient-header-breadcrumb-btn {
  padding: 0;
}
.patient-header-breadcrumb-btn:hover {
  background-color: transparent;
}

/* MAIN CARD */
.patient-card .ant-card-head {
  border-bottom: 0px;
}

/* INFO CARD */
.info-card {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.info-card-chart-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
}

.info-card-title {
  font-size: 1.2em;
  font-weight: 500;
  color: @black;
}

.info-card-button-row {
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.info-card-button-row .info-card-title {
  margin-right: 8px;
}

.info-card-button-row .info-card-button-group {
  margin-left: auto;
}

.info-card-data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 8px;
}

.info-card-row {
  margin-top: 8px;
}

.info-card-progress .ant-progress-text {
  color: @black;
}

.chart-wrapper {
  margin-top: 20px;
}

// Unused divider with text border color changer
// .info-card-charts-divider.ant-divider-horizontal.ant-divider-with-text-center::before,
// .info-card-charts-divider.ant-divider-horizontal.ant-divider-with-text-center::after
// {
//   border-top: 2px solid grey;
// }

@font-size-base: 16px;@font-size-sm: 14px;@dropdown-line-height: 24px;@modal-header-title-line-height: 24px;@font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;