@import url("https://fonts.googleapis.com/css?family=Lato");

@white: #fff;

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grecaptcha-badge {
  opacity: 0;
}

.full-screen {
  min-height: 100vh;
  overflow: auto;
}

.center-text {
  text-align: center;
}
