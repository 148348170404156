/* Patient List */

.patient-list-card .ant-card-head {
  border-bottom: 0px;
}

// List Styles
.patient-list-card .ant-card-body {
  padding-top: 0px;
}

.patient-list-content {
  margin-left: 40px;
  vertical-align: middle;
}

.patient-list-content .patient-list-content-text {
  margin-bottom: 0px;
}

// Card/Grid Styles
.patient-list-grid-card {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.patient-list-grid-card .ant-card-body {
  display: flex;
}

.patient-list-grid-content {
  vertical-align: middle;
}
.patient-list-grid-content .patient-list-content-text {
  margin-bottom: 0px;
}

@font-size-base: 16px;@font-size-sm: 14px;@dropdown-line-height: 24px;@modal-header-title-line-height: 24px;@font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;